import { mapGetters } from 'vuex';
import { updateNestedObjectFromPath } from '@/helpers/common';

export const address = ({ formName }) => {
  return {
    data() {
      return {
        newMuniciparity: '',
      };
    },
    mounted() {
      this.$store.dispatch('common/getPrefectures');
    },
    computed: {
      ...mapGetters({
        prefectures: 'common/prefectures',
        municipalities: 'common/municipalities',
      }),
    },
    methods: {
      async getMunicipalities(prefectureId, municipalityId) {
        if (prefectureId) await this.$store.dispatch('common/getMunicipalities', prefectureId);
        if (!this.municipalities.find((item) => item.id == municipalityId)) {
          updateNestedObjectFromPath(this.$data, '', `${formName}.municipalityId`);
        }
        if (this.newMuniciparity) {
          const currentMunicipalityId = (this.municipalities.find((item) => item.id === this.newMuniciparity) || {}).id;
          updateNestedObjectFromPath(this.$data, currentMunicipalityId, `${formName}.municipalityId`);
          this.newMuniciparity = '';
        }
      },
      async getAddress(event) {
        const zipcode = event.target.value;
        if (zipcode.length === 7) {
          let res = await this.$store.dispatch('common/getAddressFromZipcode', zipcode);
          if (res) {
            updateNestedObjectFromPath(this.$data, res.prefecture.id, `${formName}.prefectureId`);
            updateNestedObjectFromPath(this.$data, res.townName, `${formName}.streetAddress`);
            this.newMuniciparity = res.municipality.id;
          } else {
            this.$store.dispatch('common/resetMunicipalities');
            updateNestedObjectFromPath(this.$data, '', `${formName}.prefectureId`);
            updateNestedObjectFromPath(this.$data, '', `${formName}.streetAddress`);
          }
        }
      },
    },
  };
};
