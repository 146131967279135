import { debounce } from 'lodash';
import axios from 'axios';
import { urlGoogleApis } from '@/constants/url';

export default {
  name: 'googleMap',
  data: function() {
    return {
      map: null,
      marker: null,
    }
  },
  watch: {
    "form.latLng"(val) {
      this.marker.position = { ...val };
      this.map.setCenter({ ...val });
    }
  },
  methods: {
    handleAddress(latLng) {
      const lat = latLng.lat();
      const lng = latLng.lng();
      this.form.latLng = { lat, lng }
    },
    async handleInput(e) {
      await this.getAddress(e);
      this.getLocationDebounced();
    },
    async initMarker(latLng) {
      this.map = await new window.google.maps.Map(document.getElementById("map"),  {
        zoom: 17,
        center: latLng,
        mapId: 'DEMO_MAP_ID',
      });
      if (this.map) {
        this.marker = new window.google.maps.marker.AdvancedMarkerElement({
          map: this.map,
          position: latLng,
          gmpDraggable: true,
        });
        // eslint-disable-next-line no-unused-vars
        this.marker.addListener('dragend', ({_, latLng}) => {
          this.handleAddress(latLng);
        });
      }
    },
    getLocation() {
      this.$message.reset();
      axios({
        method: 'get',
        url: urlGoogleApis,
        params: {
          address: this.address,
          key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          sensor: false,
        },
      }).then((response) => {
        const latLng = response.data.results[0]?.geometry?.location;
        if (latLng) {
          this.form.latLng = { ...latLng };
        }
      });
    }
  },
  mounted() {
    this.initMarker();
    this.getLocationDebounced = debounce(this.getLocation, 1500);
  }
}
